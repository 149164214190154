<template>
  <div class="shopList">
    <div class="listBox">
      <div class="listItem">
        <div class="list">
          <div
            class="item"
            v-for="item in oddList"
            :key="item.id"
            @click="openDetailFn(item)"
          >
            <img :src="item.goodsThumbImage" />
            <div class="content">
              <span class="title">{{ item.goodsName }}</span>
              <span class="money" :class="color"
                >{{ item.coinPrice }}工钱币
                <span class="price">市价{{ item.marketPrice }}</span></span
              >
            </div>
            <div class="jd_logo" v-if="item.channelIconUrl">
              <img :src="item.channelIconUrl" />
            </div>
          </div>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="item in evenList"
            :key="item.id"
            @click="openDetailFn(item)"
          >
            <img :src="item.goodsThumbImage" />
            <div class="content">
              <span class="title">{{ item.goodsName }}</span>
              <span class="money" :class="color"
                >{{ item.coinPrice }}工钱币
                <span class="price">市价{{ item.marketPrice }}</span></span
              >
            </div>
            <div class="jd_logo" v-if="item.channelIconUrl">
              <img :src="item.channelIconUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scrollTop" @click="scrollTop">
      <!-- <img src="@/assets/img/scrollTop.png"> -->
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  props: {
    shopList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "ff844d",
    },
  },
  setup(props) {
    const $router = useRouter();
    const state = reactive({
      type: props.type,
    });
    const oddList = computed(() => {
      return props.shopList.filter((item, index) => (index + 1) % 2 !== 0);
    });
    const evenList = computed(() => {
      return props.shopList.filter((item, index) => (index + 1) % 2 === 0);
    });
    const openDetailFn = (options) => {
      if (options.goodsChannel == "0") {
        $router.push({
          path: "/jdshop/goodsDetail",
          query: {
            goodsId: options.goodsId,
          },
        });
      } else {
        $router.push({
          path: "/exchangeshop/goodsdetail",
          query: {
            goodsId: options.goodsId,
          },
        });
      }
    };
    return {
      oddList,
      evenList,
      openDetailFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.shopList {
  width: 355px;
  margin-left: 8px;
  height: 100%;
  .listItem {
    display: flex;
    flex-direction: row;
  }
  .list {
    margin-right: 10px;
    flex-direction: column;
    width: 177px;
    .item {
      width: 175px;
      min-height: 235px;
      max-height: 270px;
      background: #ffffff;
      box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      margin-top: 10px;
      position: relative;
      img {
        width: 160px;
        height: 160px;
        padding: 8px 8px 0px 8px;
      }
      .content {
        padding: 8px;
        span {
          display: inline-block;
        }
        .price {
          font-size: 12px;
          color: #ccc;
          text-decoration: line-through;
          font-weight: 400;
        }
        .title {
          font-size: 12px;
          font-weight: 600;
          color: #333333;
          line-height: 17px;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .money {
          font-size: 14px;
          font-weight: 600;

          line-height: 14px;
          margin-top: 10px;
        }
        .ff844d {
          color: #ff844d;
        }
        .ff4242 {
          color: #ff4242;
        }
      }
      .jd_logo {
        img {
          width: 52px;
          height: 24px;
          position: absolute;
          z-index: 999;
          top: -10px;
          left: -10px;
        }
      }
      .goods-duiwan-box {
        position: absolute;
        top: 55px;
        left: 40px;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .scrollTop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999999;
    img {
      width: 34px;
      height: 34px;
    }
  }
}
</style>
