<template>
  <div class="exchangeshop">
    <div>
      <div class="exchangeshop-navigate">
        <navbar-tools :leftArrow="true"></navbar-tools>
        <div class="search-content">
          <van-search
            v-model="searchContent"
            placeholder="搜索商品名称"
            :clearable="false"
            @focus="exchangeSearchFn"
          />
        </div>
        <div class="search-condition">
          <div class="search-types">
            <div class="types-item types-classfiy">
              <div class="types-list">
                <van-tabs
                  v-model:active="classfiyActive"
                  title-active-color="#fc7301"
                  @click-tab="setCateIdFn"
                  ref="tabs"
                >
                  <van-tab
                    v-for="item in conditionObject?.categoryList"
                    :title="item.categoryName"
                    :key="item.categoryId"
                    :name="item.categoryId"
                  ></van-tab>
                </van-tabs>
              </div>
            </div>
          </div>
          <div class="search-types-money">
            <van-tabs
              v-model:active="moneyActive"
              @click-tab="setPriceIntervalIdFn"
            >
              <van-tab title="" name="" class="active moneyActive"></van-tab>
              <van-tab
                :title="item.priceIntervalName"
                class="active"
                v-for="item in conditionObject?.priceIntervalList"
                :key="item.priceIntervalId"
                :name="item.priceIntervalId"
              ></van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
      <div class="shoplistBox" v-if="nullList">
        <van-list
          finished-text="没有更多了"
          :immediate-check="false"
          v-model:loading="loading"
          :finished="finished"
          @load="onloadFn"
        >
          <shoplist :shopList="goodsList"></shoplist>
        </van-list>
      </div>
      <div v-else class="nullList">
        <img :src="OSS_URL + '/null.png'" />
      </div>
    </div>
  </div>
</template>
   
<script>
import navbarTools from "@/components/navbarTools";
import shoplist from "@/components/shoplist";
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  ref,
  nextTick,
  onMounted,
  onUpdated,
  onActivated,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { selfGoodsList, selfHomeSearchCondition } from "@/api/shop";
export default defineComponent({
  name: "exchangeshop",
  components: {
    navbarTools,
    shoplist,
  },
  setup() {
    let $router = new useRouter();
    let $route = new useRoute();
    const tabs = ref(null);
    const state = reactive({
      OSS_URL: process.env.VUE_APP_OSS_URL,
      searchContent: "",
      classfiyActive: parseInt($route.query.id) ?? null,
      moneyActive: 0,
      loading: false,
      finished: false,
      conditionObject: null,
      goodsList: [],
      typesShow: false,
      pages: {
        cateId: parseInt($route.query.id) ?? null,
        keyWords: "",
        page: 1,
        pageSize: 10,
        priceIntervalId: null,
        sortPrice: null,
        sortSales: null,
      },
      nullList: true,
    });

    const exchangeSearchFn = () => {
      $router.push({
        path: "/exchangeshop/searchshop",
      });
    };
    const selfHomeSearchConditionFn = async () => {
      let rs = await selfHomeSearchCondition();
      let obj = rs.data;
      let all = {
        categoryId: "",
        categoryName: "全部",
      };
      obj.categoryList.unshift(all);
      state.conditionObject = obj;
    };
    const selfGoodsListFn = async () => {
      let rs = await selfGoodsList(state.pages);
      state.goodsList = [...state.goodsList, ...rs.data.list];
      if (state.goodsList.length > 0) {
        state.nullList = true;
      } else {
        state.nullList = false;
      }
      if (state.pages.page >= rs.data.totalpage) {
        state.finished = true;
      } else {
        state.loading = false;
      }
    };
    const onloadFn = async () => {
      setTimeout(() => {
        state.pages.page = state.pages.page + 1;
        selfGoodsListFn();
      }, 1500);
    };
    const setPriceIntervalIdFn = (id) => {
      if (state.pages.priceIntervalId == id.name) {
        state.moneyActive = "";
        state.pages.priceIntervalId = "";
      } else {
        state.pages.priceIntervalId = id.name;
      }
      state.pages.page = 1;
      state.goodsList = [];
      state.loading = false;
      state.finished = false;
      selfGoodsListFn();
    };
    const setCateIdFn = (id) => {
      state.loading = false;
      state.finished = false;
      state.pages.cateId = id.name;
      state.pages.page = 1;
      state.goodsList = [];
      selfGoodsListFn();
    };

    $router.afterEach((to, from) => {
      if (from.path == "/exchangeshop/goodsdetail") {
        to.meta.isUseCache = false;
      } else {
        to.meta.isUseCache = true;
      }
    });
    onActivated(() => {
      if ($route.meta.isUseCache) {
        state.searchContent = "";
        state.classfiyActive = parseInt($route.query.id) ?? null;
        (state.moneyActive = 0),
          (state.loading = false),
          (state.finished = false),
          (state.conditionObject = null),
          (state.goodsList = []),
          (state.typesShow = false),
          (state.pages = {
            cateId: parseInt($route.query.id) ?? null,
            keyWords: "",
            page: 1,
            pageSize: 10,
            priceIntervalId: null,
            sortPrice: null,
            sortSales: null,
          });
        selfHomeSearchConditionFn();
        selfGoodsListFn();
      }
    });
    return {
      selfGoodsListFn,
      selfHomeSearchConditionFn,
      exchangeSearchFn,
      onloadFn,
      setPriceIntervalIdFn,
      setCateIdFn,
      tabs,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.navbar) {
  width: 375px;
  .van-nav-bar {
    background: #fc7301;
    .van-nav-bar__left .van-icon {
      font-size: 24px;
      color: #fff;
    }
    .van-nav-bar__title {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.exchangeshop {
  .exchangeshop-navigate {
    position: sticky;
    z-index: 999;
    top: 0;
  }
  background: #f2f2f2;
  height: 100%;
  .search-content {
    width: 100%;
    background: #fc7301;
    height: 70px;
    .van-search {
      background: #fc7301;
      height: 32px;
      padding-top: 30px;
      ::v-deep(.van-search__content) {
        height: 32px;
        .van-icon {
          color: #666;
          font-size: 16px;
        }
      }
      input {
        color: #666;
        font-size: 14px;
      }
    }
  }
  .search-condition {
    height: 80px;
    background: #fff;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    top: -10px;
    .search-types {
      display: flex;
      ::v-deep(.types-classfiy) {
        .van-tabs {
          .van-tabs__nav {
            background: transparent;
            .van-tab {
              flex: none;
              font-size: 16px;
              color: #333;
              margin: 0px 12px;
              padding: 0px;
              font-weight: 500;
            }
          }

          .van-tabs__line {
            width: 18px;
            height: 4px;
            background: #fc7301;
          }
        }
      }
      .types-list {
        width: 355px;
      }
      .types-items {
        img {
          width: 80px;
          height: 45px;
        }
      }
    }
    .search-types-money {
      ::v-deep(.van-tab) {
        flex: none;
        margin: 0px 5px;
        padding: 0px;
        .van-tab__text {
          font-size: 12px;
          color: #333;
          background: #f6f6f6;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }
      ::v-deep(.van-tab--active) {
        .van-tab__text {
          font-size: 12px;
          color: #fff;
          background: #fc7301;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }
      ::v-deep(.van-tabs__line) {
        display: none;
      }
      ::v-deep(.van-tab:nth-child(1)) {
        position: absolute;
        opacity: 0;
      }
    }
  }
  .nullList {
    margin-top: 160px;
    text-align: center;
    img {
      width: 138px;
      height: 163px;
    }
  }
}
</style>